import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import logo from './assets/logo.png';
import banner from './assets/mod7.jpg';
import banner1 from './assets/mod212.JPG';
import banner2 from './assets/slider33.jpg';
import banner3 from './assets/slider7.JPG';
import banner4 from './assets/slider11.JPG';
import banner5 from './assets/slider22.jpg';
import banner6 from './assets/slider13.JPG';
import banner7 from './assets/sliedr12.JPG';
import banner8 from './assets/seil.jpg';
import bgImage from './assets/black.jpg';
import download from './assets/download.png';
import stats from './assets/slider5.JPG';
import banner11 from './assets/mod10.jpg';
import banner12 from './assets/mod8.jpg';
import banner13 from './assets/pooja111.jpg';
import banner14 from './assets/falsing.jpg';
import banner15 from './assets/tv.jpg';
import one from './assets/ban.jpg';
import kitchen1 from './assets/mod1.jpg';
import kitchen2 from './assets/mod2.jpg';
import kitchen3 from './assets/mod5.jpg';
import wadd from './assets/wadd.JPG';
import wadd1 from './assets/wardrobe1.jpg';
import wadd2 from './assets/wardrobe2.jpg';
import wadd3 from './assets/wardrobe5.jpg';
import wadd4 from './assets/wardrobe7.jpg';
import tvp from './assets/tvpanel6.jpg';
import tvp1 from './assets/tvpanel2.jpg';
import tvp2 from './assets/tvpanel19.jpg';
import tvp3 from './assets/tvpanel9.jpg';
import tvp4 from './assets/tvpanel3.jpg';
import poojaa from './assets/pooja1.jpg';
import poojaa1 from './assets/pooja3.jpg';
import poojaa2 from './assets/pooja4.jpg';
import poojaa3 from './assets/pooja7.jpg';
import poojaa4 from './assets/pooja5.jpg';
import falsee from './assets/false11.jpg';
import falsee1 from './assets/false8.jpg';
import falsee2 from './assets/false1.jpg';
import falsee3 from './assets/false5.jpg';
import falsee4 from './assets/false6.jpg';
import wallp from './assets/wall1.jpg';
import wallp1 from './assets/wall2.jpg';
import wallp2 from './assets/wall3.jpg';
import wallp3 from './assets/wall4.jpg';
import wallp4 from './assets/wall5.jpg';
import show from './assets/show11.jpg';
import show1 from './assets/show1.JPG';
import show2 from './assets/show2.jpg';
import show3 from './assets/show3.jpg';
import show4 from './assets/show4.JPG';
import shel from './assets/shelves4.jpg';
import shel1 from './assets/shelves3.jpg';
import shel2 from './assets/shelves5.jpg';
import shel3 from './assets/shelves6.jpg';
import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { MdOutlineStarBorderPurple500 } from "react-icons/md";
import { IoIosSend } from "react-icons/io";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Contact from './pages/contact';
import About from './pages/about';
import ModularKitchen from './pages/modular-kitchen';
import Wardrobe from './pages/wardrobe';
import PoojaRoom from './pages/pooja-room';
import TvPanel from './pages/tv-panel';
import WallPanel from './pages/wall-panel';
import WallShelves from './pages/wall-shelves';
import ShowCase from './pages/show-case.js';
import FalseCeiling from './pages/false-celling';
import OfficeInterior from './pages/office-interior';
import Testimonial from './pages/testimonials';

import bann from './assets/89.jpg';
import bann1 from './assets/44.JPG';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// import required modules
import './styles.css';

// import required modules
const contentData = [
  {

    title: "World Class Modular Kitchen Designs",
    description: "Our modular kitchen designs combine functionality with modern aesthetics, offering you a culinary space that's both stylish and efficient. Each kitchen is crafted to meet your specific needs, ensuring an organized and visually appealing environment."
  },
  {
    title: "Customized Wardrobe As Per Your Needs",
    description: "Tailored to your unique requirements, our custom wardrobes offer the perfect blend of style and practicality. From spacious layouts to smart storage solutions, our designs ensure every inch of space is utilized efficiently."
  },
  {
    title: "Elegant Kitchen Is Thinking Made Visual",
    description: "We believe in transforming ideas into reality. Our elegant kitchen designs are a testament to this philosophy, bringing together innovation and beauty to create spaces that inspire culinary creativity."
  },
  {
    title: "Best Possible Use Of Space Interior Designs",
    description: "Maximize your living space with our expertly designed interiors that make the best possible use of every square foot. Our designs focus on creating a harmonious balance between style and functionality, perfect for modern living."
  },
  {
    title: "Innovative TV Panel Designs for Modern Homes",
    description: "Experience the blend of functionality and aesthetics with our TV panel designs, tailored to complement your living space while offering practical storage solutions."
  },
  {
    title: "Pooja Room Designs That Radiate Serenity",
    description: "Create a peaceful sanctuary in your home with our pooja room designs, crafted to inspire tranquility and spiritual connection."
  },
  {
    title: "Sophisticated Wood Carving for Timeless Interiors",
    description: "Enhance the ambiance of any room with our unique false ceiling designs, offering both elegance and improved lighting solutions for a perfect finish."
  },

  // Add more content data here if necessary
];



function App() {


  return (
    <div className="flex flex-col min-h-screen px-auto">
      <Routes>
        <Route path="/" element={<MainLayout />}>
        </Route>
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/modular-kitchen" element={<ModularKitchen />} />
        <Route path="/wardrobe" element={<Wardrobe />} />
        <Route path="/pooja-room" element={<PoojaRoom />} />
        <Route path="/tv-panel" element={<TvPanel />} />
        <Route path="/wall-panel" element={<WallPanel />} />
        <Route path="/wall-shelves" element={<WallShelves />} />
        <Route path="/show-case" element={<ShowCase />} />
        <Route path="/false-ceiling" element={<FalseCeiling />} />
        <Route path="/office-interior" element={<OfficeInterior />} />
        <Route path="/testimonials" element={<Testimonial />} />
        {/* Add more routes if necessary */}
      </Routes>
    </div>
  );
}

function MainLayout() {
  return (
    <>
      <Header />
      <HeroSection />
      <Section1 />
      <StatsSection />
      <Section2 />
      <Project />
      <ProjectsSection />
      <Testimonials />
      <Footer />
      {/* <ScrollToTopButton /> */}
    </>
  );
}


function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false); // For category dropdown toggle

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleCategory = () => setCategoryOpen(!categoryOpen);
  return (

    <header className=" bg-[#f8f8f8] shadow-md ">
      <div className="flex mx-auto ml-10 min-md:hidden lg:ml-24 ">
        <div className="relative z-10 flex w-7/12 mx-auto mt-5 position: md:p-6">
          <img src={logo} alt="Logo" className="h-16 max-sm:h-14 lg:mr-24 md:mr-4" />

          <nav className="hidden mx-auto space-x-8 font-sans font-semibold text-gray-700 md:flex md:ml-20 lg:ml-0">
            <Link to="/" className="hover:text-[#8c2c2c]">Home</Link>
            <Link to="/about" className="hover:text-[#8c2c2c]">About</Link>
            <div className="relative group">
              <button className="hover:text-[#8c2c2c]">Category</button>
              <div className="absolute hidden w-48 mt-2 bg-white shadow-lg group-hover:block">
                <Link to="modular-kitchen" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Modular Kitchen</Link>
                <Link to="Wardrobe" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Wardrobe</Link>
                <Link to="pooja-room" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Pooja Room</Link>
                <Link to="tv-panel" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Tv Panel</Link>
                <Link to="wall-panel" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Wall Panel</Link>
                <Link to="wall-shelves" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Wall Shelves</Link>
                <Link to="show-case" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Show Case</Link>
                <Link to="false-ceiling" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">False Ceiling</Link>
                <Link to="office-interior" className="block px-4 py-2 text-gray-700 hover:bg-black hover:text-white">Office Interior</Link>

              </div>
            </div>
            <Link to="/testimonials" className="hover:text-[#8c2c2c]">Testimonials</Link>
            <Link to="/contact" className="hover:text-[#8c2c2c]">Contact</Link>
          </nav>
        </div>

        {/* Mobile Menu Button */}
        <div className="w-5/12 h-[100vh] md:bg-[#f8f8f8] lg:bg-black bg-[#f8f8f8] md:max-md:pl-20  max-sm:pl-20 max-sm:pt-5">
          <button
            onClick={toggleMenu}
            className="p-2 text-black lg:text-white md:text-[#f8f8f8]  lg:hidden sm:pl-30 sm:pt-10  md:pt-5 md:pl-20"
          >
            ☰
          </button>
        </div>
      </div>

      {/* Mobile Nav */}
      {menuOpen && (
        <div className="fixed top-0 left-0 z-50 flex flex-col items-center justify-center w-full h-full p-4 text-white bg-black md:hidden">
          {/* Close Button */}
          <button
            onClick={toggleMenu}
            className="absolute text-3xl text-white top-5 right-5"
          >
            ✖
          </button>
          <nav className="flex flex-col space-y-6 text-xl text-center">
            <Link to="/" className="hover:text-gray-400" onClick={toggleMenu}>Home</Link>
            <Link to="/about" className="hover:text-gray-400" onClick={toggleMenu}>About</Link>

            {/* Category Dropdown in Mobile View */}
            <div className="relative">
              <button
                className="flex items-center justify-center hover:text-gray-400"
                onClick={toggleCategory}>
                Category
                <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
              </button>
              {categoryOpen && (
                <div className="mt-2 bg-white items-left">
                  <Link to="/modular-kitchen" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Modular Kitchen</Link>
                  <Link to="/Wardrobe" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wardrobe</Link>
                  <Link to="/pooja-room" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Pooja Room</Link>
                  <Link to="/tv-panel" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Tv Panel</Link>
                  <Link to="/wall-panel" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wall Panel</Link>
                  <Link to="/wall-shelves" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wall Shelves</Link>
                  <Link to="/show-case" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Show Case</Link>
                  <Link to="/false-ceiling" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>False Ceiling</Link>
                  <Link to="/office-interior" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Office Interior</Link>
                </div>
              )}
            </div>

            <Link to="/testimonials" className="hover:text-gray-400" onClick={toggleMenu}>Testimonials</Link>
            <Link to="/contact" className="hover:text-gray-400" onClick={toggleMenu}>Contact</Link>
          </nav>
        </div>
      )}
    </header>

  );
}


function HeroSection() {
  // Define activeIndex and setActiveIndex
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    // Auto-transition the content every 3 seconds to match the Swiper's autoplay
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % contentData.length);
    }, 3000); // 3 seconds interval
    return () => clearInterval(interval);
  }, []);
  return (
    <section className="mx-auto max-lg:px-32 lg:px-10 pt-10 min-height lg:z-1 position: absolute w-[100%] lg:h-[100%] flex flex-col  max-sm:mt-28 ">
      {/* Left Side - Text Content */}
      <div className=" justify-center max-sm:h-[75vh] lg:h-[100vh] mx-auto ml-auto container flex flex-col md:flex-col md:mt-36 lg:mt-0 md:w-[70vw]  lg:flex-row items-center  ">
        <div className="lg:w-1/2 max-md:mt-24  md:w-[90vw] space-y-2  px-5 lg:px-0 text-left md:text-left fade-in-out sm:w-[90vw]  max-sm:mt-20  max-sm:w-[90vw]">

          <h4 className="font-sans text-3xl font-bold text-gray-900 lg:mr-20 lg:text-6xl md:text-5xl sm:text-5xl sm:top-16 lg:mt-10">
            {contentData[activeIndex].title}
          </h4><br></br>
          <p className="font-sans font-semibold text-gray-600 lg:mr-20">
            {contentData[activeIndex].description}
          </p><br></br>
          {/* <a href="#about">
          <button className="px-6 py-3 font-semibold text-white bg-black rounded hover:bg-black hover:text-white">
            Read More
          </button></a> */}
        </div>

        {/* Right Side - Bordered Image */}
        <div className="flex justify-center mt-8 min-md:mb-50 lg:px-6 lg:w-1/2 lg:-mr-8 md: lg:ml-20 md:mt-0 md:justify-end lg:py-9 lg:pl-5">
          <div className=" w-[50vw] lg:h-[70vh]  md:w-[90vw]  lg:w-[50vw]  max-sm:w-[90vw] max-sm:h-[50vh] max-md:h-[100vh] md:mt-10 md:mb-20 md:h-[90vh] border-4 border-white p-4 max-md:w-[80vw] ">
            <Swiper
              pagination={true}
              modules={[Pagination, Autoplay]}
              autoplay={{
                delay: 3000, // 3 seconds delay between slides
                disableOnInteraction: false, // Keep autoplay running even after user interaction
              }}
              onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)} // Update activeIndex on slide change
              className="mySwiper"
            >
              <SwiperSlide><img src={banner1} alt="Interior Design" className="w-full brightness-100" /></SwiperSlide>
              <SwiperSlide><img src={banner3} alt="Interior Design" className="w-full brightness-110" /></SwiperSlide>
              <SwiperSlide><img src={banner2} alt="Interior Design" className="w-full brightness-110" /></SwiperSlide>
              <SwiperSlide><img src={banner7} alt="Interior Design" className="w-full brightness-100" /></SwiperSlide>
              <SwiperSlide><img src={banner4} alt="Interior Design" className="w-full brightness-110" /></SwiperSlide>
              <SwiperSlide><img src={banner5} alt="Interior Design" className="w-full brightness-110" /></SwiperSlide>
              <SwiperSlide><img src={banner6} alt="Interior Design" className="w-full brightness-110" /></SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
function Section1() {
  return (
    <section className="max-md:pt-96  md:pt-36 lg:pt-4 max-sm:pt-36 sm:pt-10  bg-[#f8f8f8] px-6 lg:px-16 ">
      <div className="container flex flex-col items-center mx-auto mb-20 lg:mt-20 lg:flex-row md:flex-col-reverse">
        {/* Left Side - Image */}
        <div className="flex justify-center lg:w-[100vw] lg:mt-0  md:mt-10 md:w-[90vw] md:justify-start">
          <img
            src={banner}
            alt="Banner"
            className="brightness-125 lg:w-full h-auto md:w-[100vw] md:h-[80vh] max-sm:w-[100vw] object-cover border-4 border-white p-4"
          />
        </div>

        {/* Right Side - Content */}
        <div className="flex flex-col items-start justify-center w-full mt-5 space-y-4 md:w-[60w] md:mt-80 lg:mt-0  md:ml-10">
          <span className="font-sans text-4xl font-semibold text-black lg:text-9xl md:text-5xl opacity-10">
            About us
          </span>
          <h5 className="text-2xl font-semibold text-gray-900 md:text-3xl">
            Welcome To <span className="text-[#8c2c2c]">Elegant Kitchen!</span>
          </h5>
          <p className="font-sans text-gray-600">
            We welcome you to our wonderful world of Interior and Furniture. We
            are one of the leading crafters of quality modular kitchens since
            2009. We have a well-maintained state-of-the-art infrastructure
            with modern machinery and a well-trained workforce to fulfill the
            needs of our customers. We have the ability to offer the solution of
            Designing, Manufacturing, and Facility Management Services
            according to your favorable choice. We are Elegant Kitchen. You
            dream, we make it real for you. We specialize in Modular Kitchens,
            Wardrobes, Fall Ceiling, Pooja Design, Wood Carving, and TV
            Showcases.
          </p>
          <Link to="/about">
            <button className="px-6 py-3 font-semibold text-white bg-black rounded hover:bg-black">
              Know More
            </button></Link>
        </div>
      </div>
    </section>
  );
}

function StatsSection() {
  return (
    <section className="relative flex flex-col items-center justify-center w-full h-auto py-16 mx-0">

      {/* Heading Section */}
      <div className="w-full mb-8 text-center">
        <h2 className="pb-2 font-sans text-4xl font-bold text-gray-900 md:text-5xl lg:text-5xl">
          Where Your <span className="p-2 bg-black rounded-full animate-pulse">✨</span>  Dream <br></br>Space Comes to Life
        </h2>
      </div>

      {/* Main Image (Centered) */}
      <div className="relative w-full max-w-5xl">
        <img
          src={stats} // Replace with your image path
          alt="Living Space"
          className="object-cover w-full h-auto mx-auto rounded-lg brightness-125"
        />

        {/* Left Box (Horizontal Move) */}
        <div className="absolute left-0 transition-transform duration-500 transform -translate-x-20 -translate-y-1/2 top-1/2 hover:translate-x-0">
          <div className="p-6 text-center bg-white rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold text-gray-800">100+</h3>
            <p className="text-sm text-gray-600">Complete project</p>
          </div>
        </div>

        {/* Right Box (Vertical Move) */}
        <div className="absolute right-0 transition-transform duration-500 transform translate-y-20 -translate-y-1/2 top-1/2 hover:-translate-y-0">
          <div className="p-6 text-center bg-white rounded-lg shadow-lg">

            <p className="font-sans font-semibold text-gray-800 text-1xl">Crafting Your Dream Interiors with <br></br>Elegance and Precision Since 2009</p>

          </div>
        </div>
      </div>
    </section>
  );
}

function Section2() {
  const features = [
    {
      title: "Understand what you need",
      description:
        "We understand you and the requirements of your living space. Our technicians work with you to look for the precise balance between aesthetics and functionality ensuring budget, time and quality goals are as per expectations.",
    },
    {
      title: "Precise Engineering",
      description:
        "We practice strong expertise and advanced equipment in modular kitchen & Interior designing.",
    },
    {
      title: "Designs for all tastes and styles",
      description:
        "Our designs cater to diverse tastes and styles, ensuring everyone finds something they love.",
    },
    {
      title: "Highest Performance",
      description:
        "We commit to delivering timeless designs, innovative ideas, and superior craftsmanship for every project.",
    },
  ];

  return (
    <section className="relative py-20 text-white lg:pt-30" style={{ backgroundColor: 'black' }}>
      <div
        className="absolute inset-0 bg-cover bg-center z-0 opacity-40 max-xl:h-[60vh] lg:h-[50vh] sm:h-[87vh] md:h-[54vh]   max-sm:h-[91vh] min-sm:h-[111vh]"
        style={{ backgroundImage: `url(${bgImage})` }}
      ></div>
      <div className="relative z-10">
        {/* Your content goes here */}
      </div>
      <div className="container px-5 py-10 mx-auto text-center">
        <h2 className="mb-6 text-4xl font-semibold underline underline-offset-8 ">WHY ELEGANT KITCHEN?</h2>

        <p className="text-lg text-justify">
          We understand your needs and the unique requirements of your living space. Our technicians collaborate closely with you to achieve the perfect balance between aesthetics and functionality, ensuring that your budget, timeline, and quality expectations are met. With a wide range of designs tailored to all tastes and styles, we offer customization to fit your specific preferences. Our precise engineering, combined with advanced equipment and strong expertise in modular kitchen and interior design, guarantees the highest performance. We are committed to delivering timeless designs, innovative ideas, and expert craftsmanship, ensuring top-quality products and services. Transparency is at the core of our approach, as we provide clear and accurate details in all our dealings.</p>
      </div>
    </section>
  );
}
function Project() {
  return (
    <div className="px-4 mx-auto my-16 ">
      {/* Heading Section */}
      <div className="w-full mb-10 text-center">
        <span className="font-sans font-semibold text-[#8c2c2c] underline underline-offset-8 ">Elegant Kitchen</span>
        <h2 className="mt-3 mb-2 text-4xl font-bold">Few Of Our Crafts Made With Love</h2>
        {/* <p className="text-gray-600">
          Nulla vitae ex nunc. Morbi quis purus convallis, fermentum hioon metus
          volutpat design sodales purus. Nunc quis an mauris etion eros
          vulputate mattis Nulla vitae ex nunc.
        </p> */}
      </div>

      {/* Image Section */}
      <div className="flex flex-col gap-8 lg:flex-row">
        {/* First Image with Hover Effect */}
        <div className="relative w-full group lg:w-1/2">
          <img
            src={bann}
            alt="Kitchen Design"
            className="object-cover w-full h-full brightness-110 "
          />
          <div className="absolute inset-0 flex items-end p-4 transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100">
            <div>
            <Link to="/modular-kitchen">
                <h4 className="mb-1 text-xl font-bold text-white">
                  Modular Kitchens Design
                </h4></Link>
              <hr className="w-20 mb-2 border-yellow-500" />
              <p className="text-white">We understand how much time you spend in your kitchen every day, and we understand your need for a kitchen that understands you…</p>
            </div>
          </div>
        </div>

        {/* Second Image with Hover Effect */}
        <div className="relative w-full group lg:w-1/2">
          <img
            src={bann1}
            alt="Modern Building"
            className="object-cover w-full h-full brightness-110 "
          />
          <div className="absolute inset-0 flex items-end p-4 transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100">
            <div>
              <Link to="wardrobe" >
                <h4 className="mb-1 text-xl font-bold text-white">
                  Wardrobe Design
                </h4>
                </Link>
              <hr className="w-20 mb-2 border-yellow-500" />
              <p className="text-white">We have you covered with our limitless wardrobe interior design, whether you select a sliding modular wardrobe, hinged high gloss…</p>
            </div>
          </div>
        </div>


      </div>

      {/* Image Section */}
      <div className="flex flex-col gap-8 py-10 lg:flex-row">
        {/* First Image with Hover Effect */}
        <div className="relative w-full group lg:w-6/12">
          <img
            src={banner13}
            alt="Kitchen Design"
            className="w-full h-[50vh] object-cover "
          />
          <div className="absolute inset-0 flex items-end p-4 transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100">
            <div>
              <Link to="pooja-room">
                <h4 className="mb-1 text-xl font-bold text-white">
                  Pooja Room Design
                </h4>
                </Link>
              <hr className="w-20 mb-2 border-yellow-500" />
              <p className="text-white">A pooja room serves as a haven for tranquilly and prayer, and we are experts at incorporating these qualities into our pooja room designs…</p>
            </div>
          </div>
        </div>

        {/* Second Image with Hover Effect */}
        <div className="relative w-full group lg:w-3/12 ">
          <img
            src={banner14}
            alt="Modern Building"
            className="brightness-110  w-full h-[50vh] object-cover "
          />
          <div className="absolute inset-0 flex items-end p-4 transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100">
            <div>
              <Link to="false-ceiling">
                <h4 className="mb-1 text-xl font-bold text-white">
                  False Ceiling Design
                </h4>
                </Link>
              <hr className="w-20 mb-2 border-yellow-500" />
              <p className="text-white">Elegant Kitchen offers a modern and hi-tech best False Ceiling in Coimbatore. We provide false ceilings in various types… </p>
            </div>
          </div>
        </div>
        <div className="relative w-full group lg:w-3/12">
          <img
            src={banner15}
            alt="Kitchen Design"
            className="w-full h-[50vh] object-cover "
          />
          <div className="absolute inset-0 flex items-end p-4 transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100">
            <div>
              <Link to="tv-panel">
                <h4 className="mb-1 text-xl font-bold text-white">
                  TV panel Design
                </h4>
                </Link>
              <hr className="w-20 mb-2 border-yellow-500" />
              <p className="text-white">Add a stylish tv panel to your living room. Customized perfectly matching panels for your TV, LED and LCDs…. </p>
            </div>
          </div>
        </div>


      </div>

    </div>

  );
};




function ProjectsSection() {
  const [filter, setFilter] = useState('Modular Kitchen');
  const [filter1, setFilter1] = useState('Wardrobe');
  const [filter2, setFilter2] = useState('TV Panel design');

  const images = {
    'Modular Kitchen': [
      { id: 1, src: kitchen3, alt: 'Kitchen Project 1', className: ' col-span-2' },
      { id: 2, src: kitchen1, alt: 'Project 2' },
      { id: 3, src: kitchen2, alt: 'Project 3' },
      { id: 4, src: banner11, alt: 'Project 4' },
      { id: 5, src: banner12, alt: 'Project 5' },

    ],
    'Wardrobe': [
      { id: 1, src: wadd, alt: 'Exterior 1', className: 'col-span-2' },
      { id: 2, src: wadd1, alt: 'Exterior 2' },
      { id: 3, src: wadd2, alt: 'Exterior 2' },
      { id: 4, src: wadd3, alt: 'Exterior 2' },
      { id: 5, src: wadd4, alt: 'Exterior 2' },
    ],
    'TV Panel design': [
      { id: 1, src: tvp, alt: 'Project 1' },
      { id: 2, src: tvp1, alt: 'Project 2' },
      { id: 3, src: tvp2, alt: 'Project 3' },
      { id: 4, src: tvp3, alt: 'Project 4' },
      { id: 5, src: tvp4, alt: 'Project 3' },

    ],
    'Pooja Design': [
      { id: 1, src: poojaa, alt: 'Worldwide 1' },
      { id: 2, src: poojaa1, alt: 'Worldwide 2' },
      { id: 3, src: poojaa2, alt: 'Worldwide 2' },
      { id: 4, src: poojaa3, alt: 'Worldwide 2' },
      { id: 5, src: poojaa4, alt: 'Worldwide 2' },
    ],
    'False Ceiling': [
      { id: 1, src: falsee, alt: 'Countrylife 1' },
      { id: 2, src: falsee1, alt: 'Countrylife 2' },
      { id: 3, src: falsee2, alt: 'Countrylife 2' },
      { id: 4, src: falsee3, alt: 'Countrylife 2' },
      { id: 5, src: falsee4, alt: 'Countrylife 2' },
    ],
    'Wall Panel Design': [
      { id: 1, src: wallp, alt: 'Countrylife 1' },
      { id: 2, src: wallp1, alt: 'Countrylife 2' },
      { id: 3, src: wallp2, alt: 'Countrylife 2' },
      { id: 4, src: wallp3, alt: 'Countrylife 2' },
      { id: 5, src: wallp4, alt: 'Countrylife 2' },
    ],
    'Showcase Design': [
      { id: 1, src: show, alt: 'Countrylife 1' },
      { id: 2, src: show1, alt: 'Countrylife 1' },
      { id: 3, src: show2, alt: 'Countrylife 1' },
      { id: 4, src: show3, alt: 'Countrylife 1' },
      { id: 5, src: show4, alt: 'Countrylife 1' },
    ],
    'Wall Shelves Design': [
      { id: 1, src: shel, alt: 'Countrylife 1' },
      { id: 2, src: shel1, alt: 'Countrylife 2' },
      { id: 3, src: shel2, alt: 'Countrylife 2' },
      { id: 4, src: shel3, alt: 'Countrylife 2' },

    ]

  };

  return (
    <section className="py-10 bg-white ">
      <div className="container px-4 mx-auto">
        <div className=''>
          {/* Heading */}
          <div className="mb-10 text-center">
            <h2 className="space-x-3 text-4xl font-bold text-gray-900">Where Form Meets Function <br />with Flair Designer</h2>
          </div>

          {/* Filter Menu */}
          <div className="grid grid-cols-1 gap-4 mb-12 sm:grid-cols-5 max-sm:grid-cols-3 lg:grid-cols-5 lg:px-5">
            {['Modular Kitchen', 'Wardrobe', 'TV Panel design', 'Pooja Design', 'False Ceiling', 'Wall Panel Design', 'Showcase Design', 'Wall Shelves Design'].map((category) => (
              <button
                key={category}
                className={`px-6 py-2 border rounded-lg font-sans font-semibold ${filter === category ? 'bg-black text-white' : 'rounded-lg border-gray-300 text-gray-700 hover:text-white hover:bg-[#8c2c2c]'} transition`}
                onClick={() => setFilter(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className='p-0 m-0 mas-sm:px-10 brightness-110 '>
        {/* Projects Slider */}
        <Swiper
          pagination={true}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 3000, // 3 seconds delay between slides
            disableOnInteraction: false, // Keep autoplay running even after user interaction
          }}
          spaceBetween={15}
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          className="pt-10"

        >

          {images[filter].map((image) => (
            <SwiperSlide key={image.id}>
              <div className="hover:scale-105 hover:-translate transition-transform duration-300 hover:space-x-2 h-[80vh]">
                <img
                  src={image.src}
                  alt={image.alt}
                  className="object-cover w-full p-0 border-white border-1"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>


    </section>
  );
}
function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials = [
    {
      name: "Dr. Rekha Manikandan and family",
      // position: "Marketing Coordinator",
      description:
        "First of all we want to wholeheartedly thank u and ur team for your wonderful interior work done in our house. As per ur company name stands “ELEGANT ” Elegancy is seen in each and every part of ur work. Without your teams diligence, hardworking, late nights and early mornings, we wouldn’t have been able to shift as per our scheduled date. Excellent work. Keep it up.We wish u all success in all ur future endeavors 👏👏👏",
      image: "path_to_your_image", // Replace with the actual image path or URL
    },
    {
      name: "Prabu",
      position: "Jakkarpalayam",
      description:
        "I am incredibly pleased with Interior Designs of kitchen and bedroom from elegant kitchen. I would definitely recommend them as they have verygood design concepts and variety of options. Fantastic service, delivery and setup was on time and well planned, which was great.",
      image: "path_to_your_image", // Replace with the actual image path or URL
    },
    {
      name: " Anantha subramaniam,",
      position: " IT Professional, Coimbatore",
      description:
        "Janarthanan sir provided us with amazing tips and ideas for every room in our home, including window treatments. He and his team members closely worked with us and helped in giving a fresh look to our home. We highly recommend them if you are looking for an affordable Interior Designer in Coimbatore . We truly enjoyed working with them . He understands your requirements & budget good & comes up with innovative ideas. He tracks the progress& makes sure everything is delivered as promised.What I like the most is that they Worked for us very passionately and mostly committed to deliver it",
      image: "path_to_your_image", // Replace with the actual image path or URL
    },
    {
      name: "Arthi S,",
      position: "Coimbatore",
      description:
        "The best interior team I ever came across.Janarthanan sir did interior for our first lovable home. He came out with new designs as per our requirement and taste. He even helped to visualise the same with 3D view and produced the output exactly same accurate way. The entire team is professional and did everything as per planned timeline. He considered our project as like his home and did the best. Also provided lot of contact and support in civil area to complete our house.Excellent work within budget and more friendly..I strongly recommend them!!",
      image: "path_to_your_image", // Replace with the actual image path or URL
    },
    // Add more testimonials as needed
  ];

  const handlePrev = () => {
    setCurrentIndex(currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1);
  };

  const handleNext = () => {
    setCurrentIndex(currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1);
  };

  return (
    <div className="relative flex-wrap h-auto pt-10 text-white bg-black items-left">
      {/* Full-width section with heading "TESTIMONIALS" */}
      <div className='flex flex-col items-center mx-10 space-y-6 max-w-6/12 md:flex-row md:space-y-0 md:space-x-6'>
        <div className="w-full mx-8 text-left md:w-1/2 md:text-left">
          <h1 className="font-sans text-1xl font-bold  md:text-1xl text-[#f3efef]">TESTIMONIALS</h1>
          <h2 className="py-5 font-sans text-4xl font-bold text-left text-white md:text-4xl">
            Discover the Art of <br className="hidden md:block"></br>Interior Transformation
          </h2>

        </div>
        <div className='w-6/12 md:w-1/2'>
          <span className='font-sans font-semibold text-white text-9xl opacity-10 max-md:text-6xl '>
            Review
          </span>

        </div>
      </div>




      {/* Split layout for image and testimonials carousel */}
      <div className="flex flex-col items-center mx-10 space-y-6 text-white bg-black rounded-lg shadow-lg max-w-6/12 md:p-6 md:flex-row md:space-y-0 md:space-x-6 ">
        {/* Left side - Image */}
        <div className="w-1/2 max-md:w-1/2 max-sm:w-full ">
          <img
            src={one}
            alt="Testimonial"
            className="w-full h-auto rounded-lg"
          />
        </div>

        {/* Right side - Testimonials carousel */}
        <div className="w-1/2 max-sm:w-full max-md:w-1/2 max-md:text-left">
          {/* Star rating */}
          <div className="flex items-center justify-center mb-4 md:justify-start">
            <div className="flex text-white ">
              <MdOutlineStarBorderPurple500 className='fill-yellow-500' />
              <MdOutlineStarBorderPurple500 className='fill-yellow-500' />
              <MdOutlineStarBorderPurple500 className='fill-yellow-500' />
              <MdOutlineStarBorderPurple500 className='fill-yellow-500' />
              <MdOutlineStarBorderPurple500 className='fill-yellow-500' />
            </div>
          </div>

          {/* Testimonial Details */}
          <h3 className="text-xl font-semibold text-white max-md:text-xl">
            {testimonials[currentIndex].name}
          </h3>
          <p className="text-sm text-white md:text-base">
            {testimonials[currentIndex].position}
          </p>
          <hr className="my-2 border-white" />
          <p className="mt-4 text-white md:text-base">
            {testimonials[currentIndex].description}
          </p>

          {/* Carousel navigation */}
          <div className="flex items-center justify-center mt-6 space-x-4">
            <button
              onClick={handlePrev}
              className="p-4 text-white hover:bg-gray-100 hover:text-black"
            >
              &larr;
            </button>
            <button
              onClick={handleNext}
              className="p-4 text-white hover:bg-gray-100 hover:text-black"
            >
              &rarr;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Footer() {
  return (
    <div>
      {/* Subscribe Newsletter Section */}
      {/* <section className="py-16 text-center bg-black">
        <div className="max-w-6xl px-4 mx-auto">
          <div className="flex flex-col items-center space-y-6 md:flex-row md:space-y-0 md:space-x-6">
            {/* <div className="w-full text-center md:w-1/2 md:text-left">
              <h2 className="mb-6 text-3xl font-bold text-white md:text-4xl md:mb-0 ">

                SUBSCRIBE FOR<br className="hidden md:block" />UPDATES
              </h2>
            </div> *
            <div className="flex items-center justify-center w-full md:w-1/2 md:justify-start">
              <div className="flex flex-col w-full space-y-4 md:flex-row md:w-auto md:space-y-0 md:space-x-4">
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  className="w-full p-3 border-none rounded-md outline-none md:w-64"
                />
                <button className="px-6 py-3 font-semibold text-white bg-black rounded-md">
                  Subscribe Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* Footer Section */}
      <footer className="text-black bg-white ">
        <div className="grid grid-cols-1 gap-8 pt-10 mx-10 lg:grid-cols-4 md:grid-cols-3">
          {/* Column 1: Logo and Description */}
          <div>
            <img src={logo} alt="Logo" className="h-20 lg:mr-24 md:mr-4 " />
            <p className='pt-5 font-sans font-medium'>We welcome you to our wonderful world of Interior and Furniture.</p>
          </div>

          {/* Column 2: Pages Links */}
          <div>
            <h4 className="mb-4 font-sans text-lg font-semibold">Useful Links</h4>
            <ul>
              <li><Link to="/about" className="pb-10 font-sans font-normal hover:underline">About Us</Link></li>
              <br />
              <li><Link to="/testimonials" className="pb-2 font-sans font-normal hover:underline">Testimonial</Link></li>
              <br />
              <li><Link to="/contact" className="pb-2 font-sans font-normal hover:underline">Contact</Link></li>      </ul>
          </div>

          {/* Column 3: Office Information */}
          <div>
            <h4 className="mb-4 font-sans text-lg font-semibold">Contact Us</h4>
            <ul>
              <li className="flex items-start mt-5 mb-4 ">
                <IoLocationSharp className="mr-4 text-4xl circle" />
                <span className=''>250/385, Padma Complex, 7th Street, Crosscut Road, Coimbatore - 641 012.</span>
              </li>
              <li className="flex items-center mb-4">
                <FaPhoneAlt className="mr-4 text-1xl circle" />
                <span className=''>+91 99407 12867</span>
              </li>
              <li className="flex items-center">
                <MdEmail className="mr-4 text-1xl circle" />
                <span className=''>elegantkitchen.cbe@gmail.com</span>
              </li>
            </ul>
          </div>

          {/* Column 4: Newsletter */}
          <div>
            <h4 className="mb-4 font-sans text-lg font-semibold">Newsletter</h4>
            <p>Contact us today for classy Design Expertise. Best Quality Solutions.</p>
            <div className="flex mt-4">
              <input
                type="email"
                placeholder="Email here"
                className="w-full p-3 text-black border border-black outline-none rounded-l-md"
              />
              <button className="p-3 text-white bg-black rounded-r-md">
                <IoIosSend />
              </button>
            </div>
          </div>
        </div>
        <div className="py-4 mx-auto mt-10 text-center text-white bg-black">
          <p>&copy; 2024 Elegant Kitchen. All rights reserved.</p>
        </div>
      </footer>
      <FloatingWhatsApp
        phoneNumber="+919843110677"  // Your WhatsApp number
        accountName="Elegant Kitchen Customer Support"  // Name that will appear in WhatsApp
        chatMessage="Hi there! How can we help you?"  // Default message
        statusMessage="Online"
        avatar="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png"  // Optional: Avatar image URL
      />

    </div>
  );
}
// const ScrollToTopButton = () => {
//   return (
//     <div className="fixed bottom-2 right-28">
//       <button
//         className="p-3 bg-gray-300 rounded-full shadow-lg hover:bg-gray-400 focus:outline-none"
//         onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
//       >
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           fill="none"
//           viewBox="0 0 24 24"
//           strokeWidth={2}
//           stroke="currentColor"
//           className="w-6 h-6 text-black"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             d="M5 15l7-7 7 7"
//           />
//         </svg>
//       </button>
//     </div>
//   );
// };


export default App;
