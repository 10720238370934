import React, { useEffect, useState, lazy } from 'react';
import { MdOutlineStarBorderPurple500 } from "react-icons/md";
import logo from '../assets/logo.png';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import '../styles.css';
import backgroundImage from '../assets/testi.png';
import { IoLocationSharp } from "react-icons/io5";
import { IoMailSharp } from "react-icons/io5";
import { IoCallSharp } from "react-icons/io5";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { IoIosSend } from "react-icons/io";
// import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { FaFacebookF, FaInstagram, FaTwitter, FaPinterestP } from 'react-icons/fa'
import { Swiper, SwiperSlide } from 'swiper/react';
import one from '../assets/ban.jpg';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function Testimonial() {
    return (
        <>
            <Header />
            <AboutBanner />
            <TestimonialsSection />
            <Footer />
        </>
    );
}

function Header() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [categoryOpen, setCategoryOpen] = useState(false); // To toggle Category dropdown

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleCategory = () => {
        setCategoryOpen(!categoryOpen); // Toggle category dropdown
    };
    return (
        <header className="py-5 bg-white shadow-md">
            <div className="flex items-center justify-between px-5 py-4 mx-auto lg:px-28">
                <div className="w-2/12 max-sm:w-[40vw]">
                    {/* Logo Section */}
                    <img src={logo} alt="Logo" className="h-12 lg:h-16" />
                </div>

                {/* Desktop Navigation Section */}
                <nav className="hidden w-10/12 px-20 py-8 space-x-6 font-semibold text-white bg-black md:flex">
                    <Link to="/" className="hover:text-[#8c2c2c]">Home</Link>
                    <Link to="/about" className="hover:text-[#8c2c2c]">About</Link>

                    {/* Category with Submenu and Dropdown Icon */}
                    <div className="relative group">
                        <button className="menu-link hover:text-[#8c2c2c] flex items-center">
                            Category

                        </button>
                        <div className="absolute hidden text-white bg-black shadow-lg submenu group-hover:block">
                            <Link to="/modular-kitchen" className="font-sans font-semibold text-white submenu-item hover:bg-black hover:text-white">Modular Kitchen</Link>
                            <Link to="/Wardrobe" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Wardrobe</Link>
                            <Link to="/pooja-room" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Pooja Room</Link>
                            <Link to="/tv-panel" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Tv Panel</Link>
                            <Link to="/wall-panel" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Wall Panel</Link>
                            <Link to="/wall-shelves" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Wall Shelves</Link>
                            <Link to="/show-case" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Show Case</Link>
                            <Link to="/false-ceiling" className="text-gray-700 submenu-item hover:bg-black hover:text-white">False Ceiling</Link>
                            <Link to="/office-interior" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Office Interior</Link>
                        </div>
                    </div>
                    <Link to="/testimonials" className="hover:text-[#8c2c2c]">Testimonials</Link>
                    <Link to="/contact" className="hover:text-[#8c2c2c]">Contact</Link>

                    {/* Social Media Links */}
                    <div className="flex-grow"></div>
                    <div className="flex items-center space-x-3">
                        <a href="https://www.facebook.com/elegantkitchencbe" target="_blank" rel="noopener noreferrer">
                            <FaFacebookF className="text-white hover:text-[#8c2c2c]" />
                        </a>
                        <a href="https://www.instagram.com/elegantkitchencbe/" target="_blank" rel="noopener noreferrer">
                            <FaInstagram className="text-white hover:text-[#8c2c2c]" />
                        </a>
                        <a href="https://x.com/Janarth01930131" target="_blank" rel="noopener noreferrer">
                            <FaTwitter className="text-white hover:text-[#8c2c2c]" />
                        </a>
                        <a href="https://www.pinterest.com/elegantkitchenkovai/" target="_blank" rel="noopener noreferrer">
                            <FaPinterestP className="text-white hover:text-[#8c2c2c]" />
                        </a>
                    </div>
                </nav>

                {/* Mobile Menu Button */}
                <button
                    onClick={toggleMenu}
                    className="p-2 text-black md:hidden lg:text-white">
                    ☰
                </button>
            </div>

            {/* Mobile Navigation */}
            {menuOpen && (
                <div className="fixed top-0 left-0 z-50 flex flex-col items-center justify-center w-full h-full p-4 text-white bg-black md:hidden">
                    {/* Close Button */}
                    <button
                        onClick={toggleMenu}
                        className="absolute text-3xl text-white top-5 right-5"
                    >
                        ✖
                    </button>
                    <nav className="flex flex-col space-y-6 text-xl text-center">
                        <Link to="/" className="hover:text-gray-400" onClick={toggleMenu}>Home</Link>
                        <Link to="/about" className="hover:text-gray-400" onClick={toggleMenu}>About</Link>

                        {/* Category Dropdown in Mobile View */}
                        <div className="relative">
                            <button
                                className="flex items-center justify-center hover:text-gray-400"
                                onClick={toggleCategory}>
                                Category
                                <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
                            </button>
                            {categoryOpen && (
                                <div className="mt-2 bg-white items-left">
                                    <Link to="/modular-kitchen" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Modular Kitchen</Link>
                                    <Link to="/Wardrobe" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wardrobe</Link>
                                    <Link to="/pooja-room" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Pooja Room</Link>
                                    <Link to="/tv-panel" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Tv Panel</Link>
                                    <Link to="/wall-panel" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wall Panel</Link>
                                    <Link to="/wall-shelves" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wall Shelves</Link>
                                    <Link to="/show-case" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Show Case</Link>
                                    <Link to="/false-ceiling" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>False Ceiling</Link>
                                    <Link to="/office-interior" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Office Interior</Link>
                                </div>
                            )}
                        </div>

                        <Link to="/testimonials" className="hover:text-gray-400" onClick={toggleMenu}>Testimonials</Link>
                        <Link to="/contact" className="hover:text-gray-400" onClick={toggleMenu}>Contact</Link>
                    </nav>
                </div>
            )}
        </header>
    );
}

function AboutBanner() {
    return (
        <section className="relative bg-center bg-cover h-96 object-fit-cover" style={{ backgroundImage: `url(${backgroundImage})` }} >
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="container relative flex flex-col justify-center h-full mx-auto text-left items-left">
                <h1 className="text-5xl font-bold text-left text-white">TESTIMONIALS</h1>
                <p className="mt-4 text-white">
                    <a href="/" className="hover:underline">Home</a> &gt; Testimonial
                </p>
            </div>
        </section>
    );
}


function TestimonialsSection() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const testimonials = [
        {
            name: "Dr. Rekha Manikandan and family",
            // position: "Marketing Coordinator",
            description:
                "First of all we want to wholeheartedly thank u and ur team for your wonderful interior work done in our house. As per ur company name stands “ELEGANT ” Elegancy is seen in each and every part of ur work. Without your teams diligence, hardworking, late nights and early mornings, we wouldn’t have been able to shift as per our scheduled date. Excellent work. Keep it up.We wish u all success in all ur future endeavors 👏👏👏",
            image: "path_to_your_image", // Replace with the actual image path or URL
        },
        {
            name: "Prabu",
            position: "Jakkarpalayam",
            description:
                "I am incredibly pleased with Interior Designs of kitchen and bedroom from elegant kitchen. I would definitely recommend them as they have verygood design concepts and variety of options. Fantastic service, delivery and setup was on time and well planned, which was great.",
            image: "path_to_your_image", // Replace with the actual image path or URL
        },
        {
            name: " Anantha subramaniam,",
            position: " IT Professional, Coimbatore",
            description:
                "Janarthanan sir provided us with amazing tips and ideas for every room in our home, including window treatments. He and his team members closely worked with us and helped in giving a fresh look to our home. We highly recommend them if you are looking for an affordable Interior Designer in Coimbatore . We truly enjoyed working with them . He understands your requirements & budget good & comes up with innovative ideas. He tracks the progress& makes sure everything is delivered as promised.What I like the most is that they Worked for us very passionately and mostly committed to deliver it",
            image: "path_to_your_image", // Replace with the actual image path or URL
        },
        {
            name: "Arthi S,",
            position: "Coimbatore",
            description:
                "The best interior team I ever came across.Janarthanan sir did interior for our first lovable home. He came out with new designs as per our requirement and taste. He even helped to visualise the same with 3D view and produced the output exactly same accurate way. The entire team is professional and did everything as per planned timeline. He considered our project as like his home and did the best. Also provided lot of contact and support in civil area to complete our house.Excellent work within budget and more friendly..I strongly recommend them!!",
            image: "path_to_your_image", // Replace with the actual image path or URL
        },
        // Add more testimonials as needed
    ];

    const handlePrev = () => {
        setCurrentIndex(currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1);
    };

    const handleNext = () => {
        setCurrentIndex(currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1);
    };

    return (
        <div className="relative flex-wrap items-center h-auto pt-10 bg-white">
            {/* Full-width section with heading "TESTIMONIALS" */}
            <div className='flex flex-col items-center mx-auto space-y-6 w-12/12 md:flex-row md:space-y-0 md:space-x-6'>
                <div className="w-full mx-8 text-left md:text-center max-sm:text-center">
                    <h1 className="font-sans text-1xl font-bold md:text-1xl text-[#8c2c2c]">TESTIMONIALS</h1>
                    <h2 className="py-5 font-sans text-4xl font-bold text-center text-gray-800 md:text-4xl">
                        Discover the Art of <br className="hidden md:block"></br>Interior Transformation
                    </h2>
                </div>
            </div>

            {/* Split layout for image and testimonials carousel */}
            <div className="flex flex-col items-center mx-auto space-y-6 bg-white rounded-lg shadow-lg max-w-6/12 md:p-6 md:flex-row md:space-y-0 md:space-x-6">
                {/* Left side - Image */}
                <div className="max-sm:w-[80vw] w-1/2 max-md:w-1/2">
                    <img
                        src={one}
                        alt="Testimonial"
                        className="w-full h-auto rounded-lg"
                    />
                </div>

                {/* Right side - Testimonials carousel */}
                <div className="w-1/2 max-md:w-1/2 max-md:text-left">
                    {/* Star rating */}
                    <div className="flex items-center justify-center mb-4 md:justify-start">
                        <div className="flex text-black">
                            <MdOutlineStarBorderPurple500 />
                            <MdOutlineStarBorderPurple500 />
                            <MdOutlineStarBorderPurple500 />
                            <MdOutlineStarBorderPurple500 />
                            <MdOutlineStarBorderPurple500 />
                        </div>
                    </div>

                    {/* Testimonial Details */}
                    <div className='max-sm:w-[50vw]'>
                        <h3 className="text-xl font-semibold text-gray-700 max-md:text-xl">
                            {testimonials[currentIndex].name}
                        </h3>
                        <p className="text-sm text-gray-500 md:text-base">
                            {testimonials[currentIndex].position}
                        </p>
                        <hr className="my-2 border-gray-300" />
                        <p className="mt-4 text-gray-600 md:text-base">
                            {testimonials[currentIndex].description}
                        </p>
                    </div>

                    {/* Carousel navigation */}
                    <div className="flex items-center justify-center mt-6 space-x-4">
                        <button
                            onClick={handlePrev}
                            className="p-4 text-black hover:bg-gray-100"
                        >
                            &larr;
                        </button>
                        <button
                            onClick={handleNext}
                            className="p-4 text-black hover:bg-gray-100"
                        >
                            &rarr;
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}


function Footer() {
    return (
        <div>

            {/* Footer Section */}
            <footer className="text-white bg-black ">
                <div className="grid grid-cols-1 gap-8 pt-10 mx-10 lg:grid-cols-4 md:grid-cols-3">
                    {/* Column 1: Logo and Description */}
                    <div>
                        <img src={logo} alt="Logo" className="h-20 lg:mr-24 md:mr-4 " />
                        <p className='pt-5 font-sans font-medium'>We welcome you to our wonderful world of Interior and Furniture.</p>
                    </div>

                    {/* Column 2: Pages Links */}
                    <div>
                        <h4 className="mb-4 font-sans text-lg font-semibold">Useful Links</h4>
                        <ul>
                            <li><Link to="/about" className="pb-10 font-sans font-normal hover:underline">About Us</Link></li>
                            <br />
                            <li><Link to="/testimonials" className="pb-2 font-sans font-normal hover:underline">Testimonial</Link></li>
                            <br />
                            <li><Link to="/contact" className="pb-2 font-sans font-normal hover:underline">Contact</Link></li>
                        </ul>
                    </div>

                    {/* Column 3: Office Information */}
                    <div>
                        <h4 className="mb-4 font-sans text-lg font-semibold">Contact Us</h4>
                        <ul>
                            <li className="flex items-start mt-5 mb-4 ">
                                <IoLocationSharp className="mr-4 text-4xl circle" />
                                <span className='pl-2'>250/385, Padma Complex, 7th Street, Crosscut Road, Coimbatore - 641 012.</span>
                            </li>
                            <li className="flex items-center mb-4">
                                <IoCallSharp className="mr-4 text-2xl" />
                                <span className='pl-2'>+91 99407 12867</span>
                            </li>
                            <li className="flex items-center">
                                <IoMailSharp className="mr-4 text-2xl" />
                                <span className='pl-2'>elegantkitchen.cbe@gmail.com</span>
                            </li>
                        </ul>
                    </div>

                    {/* Column 4: Newsletter */}
                    <div>
                        <h4 className="mb-4 font-sans text-lg font-semibold">Newsletter</h4>
                        <p>Contact us today for classy Design Expertise. Best Quality Solutions.</p>
                        <div className="flex mt-4">
                            <input
                                type="email"
                                placeholder="Email here"
                                className="w-full p-3 text-black border border-black outline-none rounded-l-md"
                            />
                            <button className="p-3 text-white bg-black rounded-r-md">
                                <IoIosSend />
                            </button>
                        </div>
                    </div>
                </div >
                <div className="py-4 mx-auto text-center text-white bg-black border-t-2 border-gray-900 mt-14">
                    <p>&copy; 2024 Elegant Kitchen. All rights reserved.</p>
                </div>
            </footer>
            <FloatingWhatsApp
                phoneNumber="+919843110677"  // Your WhatsApp number
                accountName="Elegant Kitchen Customer Support"  // Name that will appear in WhatsApp
                chatMessage="Hi there! How can we help you?"  // Default message
                statusMessage="Online"
                avatar="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png"  // Optional: Avatar image URL
            />
        </div>
    );
}

export default Testimonial;